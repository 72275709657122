<template>
    <div>
        <template v-if="!items.length">
            <p class="subtitle-1 secondary--text py-5 text-center"><v-icon color="primary" class="mr-1">fa fa-info-circle</v-icon> You haven't shared any notes yet.</p>
        </template>
        <template v-else>
                <v-data-table
                :headers="headers"
                :items="items"
                :items-per-page="10"
                class="elevation-1"
                :expanded.sync="expanded"
                item-key="uuid"
                :single-expand="true"
                mobile-breakpoint="10"
            >
                    <template v-slot:item="{ item, expand, isExpanded }">
                        <tr class="hover-pointer tr-fade-leave-active" @click="$appFn.trackEvent({name: 'view_notes_share_details'});">
                            <td @click="expand(!isExpanded)" class="font-weight-medium">
                                {{ $moment.utc(item.created_at).tz(user.timezone).format('MMM Do YYYY') }}
                            </td>
                            <td class="text-left" @click="expand(!isExpanded)">
                                <template v-if="item.revoked">
                                    <v-chip small color="error">Revoked</v-chip>
                                </template>
                                <template v-else>
                                    <v-chip small v-if="item.status == 2">Expired</v-chip>
                                    <v-chip small v-else color="success">Active</v-chip>
                                </template>
                            </td>
                            <td class="hidden-sm-and-down" @click="expand(!isExpanded)">{{ item.phone }}</td>
                            <td class="text-center hidden-sm-and-down" @click="expand(!isExpanded)">{{ item.views }}</td>
                            <td class="text-right">
                                <v-btn icon @click="expand(!isExpanded)"><v-icon>fa fa-chevron-circle-{{ (isExpanded ? 'up' : 'down')}}</v-icon></v-btn>
                            </td>
                        </tr>
                    </template>
                        <template v-slot:expanded-item="{ headers, item }">
                        <td :colspan="headers.length" style="border-bottom: 1px solid #ccc;">
                            <div class="body-2 py-3 px-4 grey lighten-4">
                                <v-row dense>
                                    <v-col cols="12" md="4" class="hidden-md-and-up">
                                        <p class="body-2 mb-1">
                                            <b>Sent to:</b><br>
                                            {{ item.phone }}
                                            <template v-if="!item.revoked">
                                                <br>
                                                <v-btn small outlined class="mt-1" v-if="item.status==1" :href="'/share/' + item.uuid + '?preview'" target="_blank" color="primary">View Share</v-btn>
                                                <v-btn small outlined class="mt-1 ml-2" v-if="item.status!=2" :loading="revoking==item.uuid" @click.native="revoke=item; $appFn.trackEvent({ action: 'nav_event', options: { action_name: 'Activity: Revoke Share' }});" color="error">Revoke Share</v-btn>
                                            </template>
                                        </p>
                                    </v-col>
                                    <v-col cols="12" md="4">
                                        <p class="body-2 mb-1">
                                            <b>Notes Sent:</b><br>
                                            <ul>
                                                <template v-for="(note, idx) in getShareNotes(item)">
                                                    <li :key="item.uuid + note + idx">{{ note }}</li>
                                                </template>
                                            </ul>
                                        </p>
                                    </v-col>
                                    <v-col cols="12" md="4">
                                        <p class="body-2 mb-1">
                                            <template v-if="item.views > 0">
                                                <span class="hidden-md-and-up"><b>{{ item.views }} {{ (item.views>1) ? 'Views' : 'View' }} &bull; Last Viewed:</b><br></span>
                                                <span class="hidden-sm-and-down"><b>Last Viewed:</b><br></span>
                                            </template>
                                            <template v-else>
                                                <b>Last Viewed:</b><br> 
                                            </template>
                                            <span v-if="item.views>0">{{ $moment.utc(item.updated_at).tz(user.timezone).format('MMM Do YYYY [at] h:mma') }}</span>
                                            <span v-else>Not yet viewed.</span>
                                        </p>
                                        <p class="body-2 mb-0 hidden-sm-and-down" v-if="item.views > 0">
                                            <b>Total Views:</b> {{ item.views }} {{ (item.views>1) ? 'views' : 'view' }}.
                                        </p>
                                    </v-col>
                                    <v-col cols="12" md="4" class="hidden-sm-and-down text-center">
                                        <v-btn small class="mt-1" outlined v-if="item.status==1" :href="'/share/' + item.uuid + '?preview'" target="_blank" color="primary">View Share</v-btn><br v-if="item.status==1" />
                                        <v-btn small class="mt-1" outlined :disabled="item.revoked || item.status==2" :loading="revoking==item.uuid" @click.native="revoke=item; $appFn.trackEvent({ action: 'nav_event', options: { action_name: 'Activity: Revoke Share' }});" color="error">Revoke Share</v-btn>
                                    </v-col>
                                </v-row>
                            </div>
                        </td>
                        </template>
            </v-data-table>
        </template>
		<Confirm v-if="revoke" title="Revoke Shared Notes" :subtitle="'Revoking notes sent to ' + revoke.phone" msg="Are you sure you want to revoke this share?" @confirmed="handleRevoke()" @closed="revoke=null" />
    </div>
</template>
<script>
    
import { mapState } from 'vuex'

export default {
    name: 'UserActivityShares',
    props: {
        items: {
            required: true,
            type: Array
        }
    },
    components: {
        Confirm: () => import('@/components/common/Confirm'),
    },
    computed: {
        ...mapState(['user']),
    },
    data: () => ({
        expanded: [],
        headers: [
          {
            text: 'Date Shared',
            align: 'start',
            sortable: true,
            value: 'created_at',
          },
          { text: 'Status', sortable: true, value: 'status', align: 'left pl-6' },
          { text: 'Recipient', sortable: true, value: 'phone', align: ' hidden-sm-and-down' },
          { text: 'Views', sortable: true, value:'views', align: 'center pl-7 hidden-sm-and-down' },
          { text: '', sortable: false, align: 'right' },
        ],
        revoke: null,
        revoking: false
    }),
    methods: {
        initData(){
            
        },
        getShareNotes(item){
            let notes = [];
            let itemNotes = item.notes.split(',');
            for(let i in itemNotes){
                notes.push(this.getNoteByUUID(itemNotes[i]));
            }
            return notes;
        },
        getNoteByUUID(uuid){
            let notes = JSON.parse(this.user.notes[0].body);
            for(let i in notes){
                if(notes[i].uuid == uuid){
                    return notes[i].title;
                }
            }
            return "Untitled/Deleted Note";
        },
        handleRevoke(){
            let revokeId = this.revoke.uuid;
            this.revoking = revokeId;
            let request = {
                endpoint: '/notes/share/' + revokeId, 
                method: 'post',
                data: {
                    '_method' : 'delete'
                },
                apiKey: this.user.api_token || null
            };
            this.$appFn.callApi(request).then((res)=>{
                if(res.status == 204){
                    this.$eventHub.$emit('snackBar', {
                        text: 'Your shared notes have been revoked.'
                    });
                }
            }).catch(()=>{
            }).finally(()=>{
                for(let i in this.items){
                    if(this.items[i].uuid == revokeId){
                        this.items[i].revoked = true;
                    }
                }
                this.$eventHub.$emit('refreshUser');
                this.revoke = null;
                this.revoking = null;
            });
        }
    },
    mounted(){
		this.initData();
	},
    watch: {
        
    }
}
</script>